// Function to generate CAPTCHA code
const generateCode = () => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const length = 6;
  let code = '';

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    code += characters.charAt(randomIndex);
  }

  window.captchaCode = code; // Store globally for validation
  return code;
};

// Function to create CAPTCHA image
const createCaptchaImage = () => {
  const code = generateCode();
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');

  canvas.width = 150;
  canvas.height = 50;

  // Set background
  context.fillStyle = '#000'; // Black background
  context.fillRect(0, 0, canvas.width, canvas.height);

  // Add text
  context.font = '30px Arial';
  context.fillStyle = '#fff'; // White text
  context.fillText(code, 20, 35);

  // Add noise lines
  for (let i = 0; i < 5; i++) {
    context.strokeStyle = '#' + Math.floor(Math.random() * 16777215).toString(16);
    context.beginPath();
    context.moveTo(Math.random() * canvas.width, Math.random() * canvas.height);
    context.lineTo(Math.random() * canvas.width, Math.random() * canvas.height);
    context.stroke();
  }

  // Append to container
  const captchaContainer = document.getElementById('captchaImage');
  if (captchaContainer) {
    captchaContainer.innerHTML = ''; // Clear old CAPTCHA
    captchaContainer.appendChild(canvas);
  }
};

// Validate email function
const validateEmail = (email) => {
  if (!email || email.trim() === "") {
    alert("Please add your email ID");
    return false;
  } 

  if (!email.includes('@')) {
    alert("Please add '@' in your email");
    return false;
  }

  if (!email.endsWith(".com")) {
    alert("Email must end with '.com'. Please enter a valid email.");
    return false;
  }

  return true;
};

// Validate CAPTCHA input
const validateCaptcha = () => {
  const userInput = document.getElementById('captchaInput').value.trim();
  
  if (userInput !== window.captchaCode) {
    alert('Incorrect CAPTCHA. Please try again.');
    createCaptchaImage(); // Regenerate CAPTCHA
    return false;
  }

  return true;
};

// Validate phone number (keeping country code but removing validation)
const validatePhoneNumber = () => {
  const contactVal = document.getElementById("Phone_number");
  const countryCodeVal = document.getElementById("country_code");

  if (!contactVal || !countryCodeVal) {
    console.error("Phone number or country code input is missing in the DOM.");
    return "";
  }

  const selectedCode = countryCodeVal.value.replace("+", "");
  const phoneNumber = contactVal.value.trim().replace(/\D/g, ""); // Remove non-numeric characters

  if (!phoneNumber) {
    alert("Phone number cannot be empty.");
    return "";
  }

  return `+${selectedCode}${phoneNumber}`;
};

// Form Submission
const FormSubmit = async (event) => {
  event.preventDefault();

  // Fetch DOM elements
  const nameVal = document.getElementById("First_Name");
  const emailVal = document.getElementById("Email");
  const companyVal = document.getElementById("Company_Name");
  const messageVal = document.getElementById("Message");
  const submitBtn = document.getElementById("form-button");

  if (!nameVal || !emailVal || !companyVal || !messageVal || !submitBtn) {
    console.error("One or more form elements are missing in the DOM.");
    return;
  }

  if (!validateEmail(emailVal.value)) return;
  if (!validateCaptcha()) return;
  const formattedPhone = validatePhoneNumber();
  if (!formattedPhone) return;

  submitBtn.setAttribute("disabled", true);

  const formData = new FormData();
  formData.append("phone", formattedPhone);
  formData.append("name", nameVal.value);
  formData.append("email", emailVal.value);
  formData.append("company_name", companyVal.value);
  formData.append("description", messageVal.value);
  formData.append("source", "contact-us");

  const options = { method: "POST", body: formData };

  try {
    const response = await fetch(`${process.env.APIURL}/blog/api/contact-us`, options);
    if (!response.ok) throw new Error("Something went wrong");

    // window.location.assign("/thankyou");

    // Reset form fields
    nameVal.value = "";
    emailVal.value = "";
    companyVal.value = "";
    document.getElementById("Phone_number").value = "";
    messageVal.value = "";
    document.getElementById("captchaInput").value = "";

    submitBtn.removeAttribute("disabled");
    createCaptchaImage(); // Reset CAPTCHA after successful submission
  } catch (error) {
    console.log(error);
    alert("There was an error submitting the form. Please try again.");
    submitBtn.removeAttribute("disabled");
  }
};
// Mapping of country codes to phone codes
const countryToPhoneCode = {
  "US": "1",
  "GB": "44",
  "IN": "91",
  "AU": "61",
  "JP": "81",
  "DE": "49",
  "FR": "33",
  "AE": "971",
  "CN": "86",
  "RU": "7",
  "BR": "55",
  "IT": "39",
  "ZA": "27",
  "ES": "34",
  "KR": "82",
  "MX": "52",
  "NL": "31",
  "SE": "46",
  "CH": "41",
  "EG": "20",
  "BE": "32",
  "NO": "47",
  "PT": "351",
  "TR": "90",
  "PL": "48",
  "NZ": "64",
  "TH": "66",
  "GR": "30",
  "PH": "63",
  "IR": "98",
  "NG": "234",
  "AF": "93",
  "DZ": "213",
  "AR": "54",
  "AT": "43",
  "BH": "973",
  "BD": "880",
  "BO": "591",
  "BW": "267",
  "CA": "1",
  "CL": "56",
  "CO": "57",
  "CR": "506",
  "HR": "385",
  "CY": "357",
  "CZ": "420",
  "DK": "45",
  "EC": "593",
  "SV": "503",
  "EE": "372",
  "FJ": "679",
  "FI": "358",
  "GE": "995",
  "GM": "220",
  "GH": "233",
  "GT": "502",
  "HK": "852",
  "HU": "36",
  "IS": "354",
  "ID": "62",
  "IQ": "964",
  "IE": "353",
  "IL": "972",
  "JO": "962",
  "KE": "254",
  "KW": "965",
  "LB": "961",
  "LY": "218",
  "LT": "370",
  "MY": "60",
  "MV": "960",
  "ML": "223",
  "MT": "356",
  "MD": "373",
  "MA": "212",
  "MZ": "258",
  "MM": "95",
  "NA": "264",
  "NP": "977",
  "NI": "505",
  "OM": "968",
  "PK": "92",
  "PA": "507",
  "PY": "595",
  "PE": "51",
  "QA": "974",
  "RO": "40",
  "SA": "966",
  "SG": "65",
  "SK": "421",
  "SI": "386",
  "LK": "94",
  "SY": "963",
  "TW": "886",
  "TZ": "255",
  "TN": "216",
  "UA": "380",
  "UY": "598",
  "VE": "58",
  "VN": "84",
  "YE": "967",
  "ZM": "260"
};
// Function to set country code based on geolocation
const setCountryCodeByGeo = async () => {
  try {
    const response = await fetch("https://ipapi.co/json/"); // Fetch location data
    if (!response.ok) throw new Error("Failed to fetch location data");
    
    const data = await response.json();
    const userCountryCode = data.country_code.toUpperCase(); // Get country code (e.g., "US")

    const phoneCode = countryToPhoneCode[userCountryCode]; // Get phone code from mapping
    const countryCodeDropdown = document.getElementById("country_code");

    if (countryCodeDropdown && phoneCode) {
      for (let option of countryCodeDropdown.options) {
        if (option.value === phoneCode) {
          option.selected = true; // Set default selection
          break;
        }
      }
    }
  } catch (error) {
    console.error("Error fetching geolocation data:", error);
  }
};


// Ensure CAPTCHA loads when page loads
window.onload = () => {
  createCaptchaImage();
  setCountryCodeByGeo(); // Set country code dynamically
};

// Attach function to global window object
window.FormSubmit = FormSubmit;
window.createCaptchaImage = createCaptchaImage;